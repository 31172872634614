<!--会员管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c  flex-wrap">
                <label>场景：</label>
                <el-input placeholder="姓名/手机号/昵称" v-model="select.keyword" size="small" style="width:350px;" @change="curr=1;getList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
            </div>
        </div>

        <!--会员统计-->
        <!-- <div class="handleLogBox">
            <el-row :gutter="10" type="flex" justify="space-between">
                <el-col v-for="(item,index) in memberTotal" :key="index" :span="parseInt(24 / (memberTotal.length))">
                    <el-card shadow="never">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #f9eef3" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #ff85c0;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-bank-card"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{item.count}}</p>
                                <p class="log_text">{{item.name}}</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </div> -->

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="id" label="编号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="realname" label="姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" width="100" label="手机号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="头像" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.headimgurl" :src="scope.row.headimgurl" class="tableImage" :preview-src-list="[scope.row.headimgurl]"></el-image>
                        <el-avatar v-else shape="square" :size="45" :src="require('../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="order_count" align="center" label="订单数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="order_amount_sum" align="center" label="订单总金额" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="recharge_count" align="center" label="充值次数" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="recharge" align="center" label="充值余额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral" align="center" label="会员积分" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" align="center" label="注册时间" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="showDetail(scope.row,'show')">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="分配门店" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="500px">
            <el-form ref="formAuth" :rules="formRules" :model="formData" size="small" label-position="top" class="alertForm">
                <el-form-item prop="warehouse_id" style="width:100%">
                    <el-select v-model="formData.warehouse_id" placeholder="选择门店" clearable>
                        <el-option v-for="item in selectData.house" :label="item.name" :value="item.id" :key="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveHouse()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { memberListApi, memberTotalApi } from '@/api/member.js';
export default {
    data() {
        return {
            formData: {}, //详情数据
            select: {
                member_id: 1
            },
            list: [], //会员信息列表
            selectData: [], //下拉数据
            formRules: {
                warehouse_id: [{
                    required: true,
                    message: '请选择门店',
                    trigger: 'change'
                }],
            },

            memberId: '',
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            dialogFormVisible: false, //弹框状态
        }
    },
    mounted() {
        this.select.warehouse_id = this.$route.query.id ? Number(this.$route.query.id) : '';
        //会员用户列表
        this.getList(); 
    },
    methods: {
        //会员用户列表
        getList: function () {
            let param = this.$route.query.date ? {
                date: this.$route.query.date,
                type: this.$route.query.type,
                curr: this.curr,
                row: this.row,
                member_id: 1
            } : {
                curr: this.curr,
                row: this.row,
                ...this.select
            }

            memberListApi(param).then(response => {
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },

        //进入详情
        showDetail: function (row) {
            this.$router.push({
                path: '/memberDet',
                query: {
                    id: row.id,
                    isMember:true
                }
            })
        },

        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getList();
        }
    }
}
</script>
